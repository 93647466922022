@import 'styles/config';

.section {
  @include section;

  &:not([class*='theme-']) + &:not([class*='theme-']) {
    padding-top: 0;
  }

  &.theme-gray {
    background-color: $color-input-background;
  }
}
