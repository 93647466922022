@import 'styles/config';

.date {
  margin-bottom: calc(var(--gutter) / 2);

  color: $color-meta;

  &.right {
    text-align: right;
  }
}
