@import 'styles/config';

.countdown {

  @include aspect-ratio(1680, 800);
  position: relative;

  margin-top: scale-to-em(30, $font-size);

  width: 100%;
  overflow: hidden;

  text-align: center;

  color: $color-background;

  &::after {
    @include fit;

    content: '';

    display: block;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.03) 0,
      rgba(0, 0, 0, 0.7) 100%
    );
  }

  &__image {
    @include fit;
    max-width: none;

    object-fit: cover;
  }

  &__inner {
    @include fit;

    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;

    z-index: 1;
  }

  &__list {
    @include grid($container: false);
  }

  &__item {
    @include grid-item(3);

    padding: 0;

    opacity: 0;

    transform: translateY(30px);

    transition: all 0.5s ease-in-out;
    transition-property: opacity, transform;

    .inView & {
      opacity: 1;

      transform: translateY(0);
    }
  }

  &__list li:nth-child(1) {
    transition-delay: 0.2s;
  }
  &__list li:nth-child(2) {
    transition-delay: 0.4s;
  }
  &__list li:nth-child(3) {
    transition-delay: 0.6s;
  }
  &__list li:nth-child(4) {
    transition-delay: 0.8s;
  }

  &__number {
    font-weight: 900;
    font-size: scale-to-em($h1-size, $font-size);
    text-shadow: 0 0 5px rgba(193, 191, 191, 1);
    font-variant-numeric: tabular-nums;
  }

  &__link {
    display: flex;
    justify-content: center;

    font-weight: 900;
    font-size: scale-to-em($h1-size, $font-size);

    text-shadow: 0 0 5px rgba(193, 191, 191, 1);

    text-decoration: none;
  }

  &__label {
    font-size: scale-to-em(12, $font-size);
    font-weight: 600;
    text-transform: uppercase;

    color: #bdbdbd;
  }

  &__message {
    position: relative;
    z-index: 1;

    padding: 30px;

    opacity: 0;

    font-size: 40px;
    font-weight: 900;

    .inView & {
      opacity: 1;

      transition: opacity 1s ease-in-out;
      transition-delay: 1s;
    }
  }
}
