@import 'styles/config';

.cta {
  align-items: center;

  position: relative;
  z-index: 0;

  &__mediaWrap {
    &.boxTop {
      padding-top: 15px;

      @media (min-width: $min-desktop) {
        padding-top: 60px;
      }
    }
    &.boxBottom {
      padding-bottom: 15px;

      @media (min-width: $min-desktop) {
        padding-bottom: 60px;
      }
    }

    margin-bottom: scale-to-em(40);

    @media (min-width: $min-desktop) {
      margin-bottom: 0;
    }
  }

  &__content {
    @media (min-width: $min-desktop) {
      grid-row: 1;
    }
  }

  &__title {
    @include transition-enter-on-active;
    @include h2;

    color: $color-secondary;

    transform: translate3d(0, 20px, 0);

    > b {
      color: $color-green;
    }
  }

  &__text:not(:empty) {
    @include transition-enter-on-active;

    transform: translate3d(0, 20px, 0);

    &:not(:first-child) {
      padding-top: scale-to-em(40);

      transition-delay: $stagger-duration;
    }
  }

  &__linkWrap {
    @include transition-enter-on-active;

    padding-top: scale-to-em(40);

    transform: translate3d(0, 20px, 0);

    transition-delay: $stagger-duration * 2;
  }

  &__link {
    position: relative;

    font-weight: bold;
    text-decoration: none;

    color: $color-secondary;

    transition: color $stagger-duration $default-ease;
    transition-delay: $stagger-duration * 2;

    &::after,
    &::before {
      content: '';

      display: inline-block;

      position: relative;
      bottom: -1px;

      margin-right: 10px;

      width: 20px;
      height: 3px;

      vertical-align: bottom;

      background-color: $color-secondary;

      transition: height 150ms ease-in-out;
    }

    &::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      z-index: -1;

      transition: height 150ms ease-in-out, width 150ms ease-in-out 250ms;
    }

    &:hover::after,
    &:focus::after,
    &:active::after {
      height: 28px;
      width: calc(100% + 30px);

      background-color: lighten($color-secondary, 7%);
    }

    &:hover::before,
    &:focus::before,
    &:active::before {
      height: 27px;

      background-color: lighten($color-secondary, 7%);
    }

    &:hover,
    &:focus {
      color: #fff;
    }
  }
}
