@import 'styles/config';

.pricesItem {
  justify-content: space-between;
  flex-direction: column;
  display: flex;

  color: $color-secondary;

  &__title{
    @media (min-width: 900px) {
      margin-bottom: 32px;
    }
  }
  &__number {
    @include h2;

    font-weight: 900;
    line-height: 1.5;
  }

  &__eyebrow {
    display: block;

    margin-bottom: -8px;

    font-size: 14px;

    color: #46c28c;
  }

  &__bigNumber {
    @include h1;
    
    font-weight: 900;
    line-height: 1.5;
  }
}

.twoPrices {
  display: flex;
  gap: 24px;
}

.twoPricesName {
  @media (min-width: 900px) {
    margin-bottom: 16px;  
  }
}
