@import 'styles/config';

.video {
  z-index: 0;

  overflow: hidden;

  &:not(.addLayout) {
    @include fit;

    width: 100%;
    height: 100%;
  }

  &.addLayout {
    position: relative;

    &::before {
      content: '';

      display: block;

      padding-bottom: calc(var(--video-aspect-ratio, 0.5625) * 100%);
    }
  }

  &:not(.cover) {
    @include media-position($fit: contain, $background: true);
  }

  &.cover {
    @include media-position($fit: cover, $background: true);
  }

  &__ratio {
    .addLayout & {
      display: block;

      padding-bottom: calc(var(--video-aspect-ratio, 0.5625) * 100%);
    }
  }

  &__media {
    @include fit;
    @include media-position($fit: contain);

    display: block;

    z-index: 0;

    max-width: none;

    object-fit: contain;

    opacity: 0;

    .cover & {
      object-fit: cover;
    }

    .hasStarted & {
      opacity: 1;

      transition: opacity 100ms linear;
    }

    .noPoster & {
      opacity: 1;
    }
  }

  &__button {
    @include fit;

    z-index: 1;

    margin: auto;

    width: 100%;
    height: 100%;

    text-align: center;
  }

  &__icon {
    width: 60px;
    height: 60px;

    color: #fff;
  }

  &__iconWrap {
    display: inline-block;

    padding: 35px 0;

    width: 130px;
    height: 130px;

    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0.5);

    transition: all 0.4s ease-in-out;
    transition-property: background-color, transform, opacity;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);

      transform: scale(0.9, 0.9);
    }
  }
}
