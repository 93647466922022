@import 'styles/config';

.numbers {
  @include container;

  &__list {
    @media (min-width: $min-tablet) {
      @include flex-grid($justify: center);
    }
  }

  .numbers__item {
    margin-bottom: scale-to-em(50);
    @media (min-width: $min-tablet) {
      @include flex-grid-item($width: 4);
      margin-bottom: 0;
    }
  }
}
