@mixin fade-enter($start: 0, $end: 1, $delay: 0ms) {
  @keyframes fade-in {
    to {
      opacity: $end;
    }
  }

  opacity: $start;

  animation: fade-in $animation-duration ease-in $delay forwards;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 1ms;
  }
}

// parent or item fades in on ".active" class being applied
@mixin transition-enter-on-active($parent: false, $transform: true) {
  $selector: '.active &';

  @if ($parent == true) {
    $selector: '&.active';
  }

  opacity: 0;

  #{$selector} {
    @if ($transform == true) {
      transform: translate3d(0, 0, 0);
    }
    opacity: 1;

    // assumption is this is triggerOnce so only animate on active state
    transition-property: opacity;
    transition-duration: $animation-duration;
    transition-timing-function: ease-in;

    @if ($transform == true) {
      transition-property: opacity, transform;
      transition-timing-function: ease-in, $default-ease;
    }

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}
