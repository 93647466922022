// PROJECT SCSS VARIABLES
// not all of these are available as css custom properties by default: they can be added @ styles/global.scss

// GRID: defaults to 12 columns with 30px gap as $gutter;
// grid gap
$gutter: 30px;

// grid columns
$grid-column-count: 12;

// <Container> - spacing either side of content; used in @container mixin
$container-gutter-mobile: 20px;
$container-gutter-tablet: 50px;
$container-gutter-desktop: 70px;

// vertical spacing between <Section>s. used to compute percentage-based values via @section mixin
$section-padding: 140px;
$section-padding-top: 40px;
$section-padding-top-mobile: 32px;
$section-padding-mobile: 90px;
$section-padding-article: 80px;
$section-padding-article-mobile: 60px;

// point at which container reaches width limit (unless fluid=true), and responsive font-sizes max-out
$page-width: 1500px;

// mq for page full-width
$page-limit: $page-width + ($container-gutter-desktop * 2);

// COMMON BREAKPOINTS: used for consistent measuring breakpoints
// also used in <Grid /> component breakpoints

$min-mobile: 480px;
$min-tablet: 720px;
$min-desktop: 1080px;
$min-wide: $page-width;
$hero-carousel-max-width: 1490px;
$screen-power-package-xxl: 1520px;
$screen-power-package-xl: 1300px;
$screen-power-package-md: 800px;

// map for breakpoint values (used in GridItem classnames)
$ui-breakpoints: (
  'mobile': $min-mobile,
  'tablet': $min-tablet,
  'desktop': $min-desktop,
  'wide': $min-wide,
);

$max-mobile: $min-mobile - 1px;
$max-tablet: $min-tablet - 1px;
$max-desktop: $min-desktop - 1px;
$max-wide: $min-wide - 1px;

// MISC ELEMENT DIMENSIONS
$header-height: 108px;
$header-inner-height: 62px;
$header-height-mobile: 0;

// Global STACK of elements
$zindex-header: 1;
$zindex-modal: $zindex-header + 1;
$zindex-cookiehub: 700001; // controlled by cookie hub
$zindex-preview: $zindex-cookiehub + 1;

// COLORS - these are also available as :root css variables in global.scss

// theme
$color-primary: #00c38b;
$color-secondary: #320564;
$color-backdrop: rgba(#000, 0.3);
$color-background: #fff;
$color-border: #ccc;
$color-border-secondary: #979797;
$color-font: #202020;
$color-font-contrast: #fff;
$color-link: $color-secondary;
$color-placeholder: #707070;
$color-chart-line: #7070704d;
$color-form-label: #000;
$color-blue: #1494ba;
$color-light-blue: #1494ba;
$color-off-white: #f3f9fb;
$color-meta: #767676;

$color-dark-grey: #656565;

$color-grey-4: #ECECEC;
$color-input-placeholder: #1C5770;
$color-input-fill: #F3F9FB;
$color-input-dropdown-open: #faf9fb;
$color-table-header:  #f3f1f4;
// optional dark mode variables
// $color-background-dark-mode: #000;
// $color-font-dark-mode: $color-font-contrast;
// $color-link-dark-mode: $color-font-dark-mode;

// misc
$color-red: #ac2a46;
$color-green-dark: #00805b;
$color-green: #00c38b; // fails contrast check
$color-error: #ff6e00;
$color-input-background: #f3f9fb;

//banner type 
$color-ui-alerts-error-dark: #FF6C76;

// TYPE (default is a locally-hosted set up)
$font-family-sans: FFMark, Helvetica, Arial, sans-serif;
$font-family: $font-family-sans;
$font-family-heading: $font-family-sans;

// font-sizes
$font-size: 18px;
$font-size-xs: 14px;
$font-size-min: 15px;
$font-size-button: 18px;
$font-size-button-min: 14px;

$font-size-input: 18px;
$font-size-input-min: 16px;

// used in heading mixins (styles/type)
$h1-size: 60px;
$h1-size-min: 48px;
$h2-size: 48px;
$h2-size-min: 28px;
$h3-size: 28px;
$h3-size-min: 24px;
$h4-size: 24px;
$h4-size-min: 20px;
$h5-size: 22px;
$h5-size-min: 18px;
$h6-size: $font-size;
$h6-size-min: $font-size-min;

$line-height: 1.5;

// element lists (for genetic style loops)
$input-types: date, datetime-local, email, month, number, password, search, tel,
  text, time, url, week;
$heading-types: h1, h2, h3, h4, h5, h6;
$block-article-elements: p, ul, ol, dl, blockquote, pre;

// form elements
$button-height: 60px;
$button-width: 220px;
$button-max-width: 300px;
$input-height: 58px;

// ANIMATION [power1, 2, 3, 4] in gsap;
// ease-in
$ease-in-quad: cubic-bezier(0.11, 0, 0.5, 0);
$ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
$ease-in-quart: cubic-bezier(0.5, 0, 0.75, 0);
$ease-in-quint: cubic-bezier(0.64, 0, 0.78, 0);

// ease-out
$ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
$ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
$ease-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
$ease-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
$ease-out: $ease-out-quad;

// ease-in-out
$ease-in-out-quad: cubic-bezier(0.45, 0, 0.55, 1);
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
$ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);

$default-ease: $ease-in-out-cubic;
$transition-duration: 300ms;
$animation-duration: 500ms;
$stagger-duration: 150ms;

$Px12Clamp: clamp(0.444rem, 0.392rem + 0.298vw, 0.667rem);
$Px14Clamp: clamp(0.667rem, 0.64rem + 0.149vw, 0.778rem);
$Px16Clamp: clamp(0.722rem, 0.683rem + 0.223vw, 0.889rem);
$Px18Clamp: clamp(0.778rem, 0.725rem + 0.298vw, 1rem);
$Px20Clamp: clamp(0.778rem, 0.698rem + 0.446vw, 1.111rem);
$Px24Clamp: clamp(0.889rem, 0.792rem + 0.446vw, 1.333rem);
$Px32Clamp: clamp(1rem, 0.815rem + 1.042vw, 1.778rem);
$Px36Clamp: clamp(1.111rem, 0.917rem + 1.042vw, 2rem);
$Px40Clamp: clamp(1.389rem, 1.19rem + 1.116vw, 2.222rem);
$Px48Clamp: clamp(1.667rem, 1.429rem + 1.116vw, 2.667rem);