@import 'styles/config';

.prices {
  @include container;
  padding: 60px 0;
      
  border-top: 1px solid $color-border-secondary;
  border-bottom: 1px solid $color-border-secondary;

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    @media (min-width: 900px) {
      flex-direction: row;
    }

    @media (min-width: $min-desktop) {
      margin: 0 auto;
      margin-bottom: scale-to-em(64px);

      max-width: 1200px;
    }
    margin-bottom: scale-to-em(64px);

  }

  &__title {
    @include h2;
    @include grid-item(4, 1);

    margin-bottom: 16px;

    font-weight: 900;

    color: $color-secondary;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

    margin-bottom: scale-to-em(20px, $font-size);
  }

  &__info {
    max-width: 300px;
  }
}

.aboutPrices{
  @include container;
  margin-top: 16px;
  padding: 0px 0px !important; 
}