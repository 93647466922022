@import 'styles/config';

.numbersItem {
  padding: 0 $gutter / 2;

  text-align: center;

  color: $color-font;

  &__valueWrap {
    position: relative;

    margin-bottom: scale-to-em(8px, $font-size);
  }

  &__label {
    @include responsive-font($min-size: 14, $max-size: 16px);
    display: block;
  }

  &__value {
    @include heading($size-min: 60px, $size: 88px);
    display: block;

    white-space: nowrap;

    font-variant-numeric: tabular-nums;

    .green & {
      color: $color-primary;
    }
  }

  &__unit {
    display: block;

    margin-top: scale-to-em(8px, $font-size);
    padding-left: 0.3ch;

    font-weight: 400;
    line-height: scale-to-em(60px, $font-size);

    white-space: nowrap;

    color: inherit;

    sub {
      font-size: 0.75em;
      vertical-align: sub;
    }

    .green & {
      color: $color-green-dark;
    }
  }

  &__text {
    &:empty {
      display: none;
    }
  }
}
