// Section spacing util, used in @section mixin but doesnt need to be used exclusively there
@function section-spacing($min: $section-padding-mobile, $max: $section-padding) {
  $min-value: strip-unit($min);
  $max-value: strip-unit($max);
  $multiplier: math.div(($max-value), strip-unit($page-width));
  $clamp: min(#{$multiplier * 150}vh, #{$multiplier * 100}vw);

  @return clamp(#{$min-value * 1px}, #{$clamp}, #{$max-value * 1px});
}

// <Section>: full-width; top & bottom padding for a section component. usually direct parent of <Container />
@mixin section(
  $padding-top: $section-padding-top,
  $padding-bottom: $section-padding,
  $padding-top-mobile: $section-padding-top-mobile,
  $padding-bottom-mobile: $section-padding-mobile
) {
  // Set up unitless values
  $top-mobile: strip-unit($padding-top-mobile);
  $bottom-mobile: strip-unit($padding-bottom-mobile);
  $top: strip-unit($padding-top);
  $bottom: strip-unit($padding-bottom);

  @supports not (padding: clamp(1px, 1vmin, 2px)) {
    padding-top: $top-mobile * 1px;
    padding-bottom: $bottom-mobile * 1px;

    @media (min-width: $min-desktop) {
      padding-top: $top * 1px;
      padding-bottom: $bottom * 1px;
    }
  }

  @supports (padding: clamp(1px, 1vmin, 2px)) {
    // mobile only padding
    @if ($top-mobile > 0 and $top == 0) {
      @media (max-width: $max-tablet) {
        padding-top: $top-mobile * 1px;
      }
    }

    @if ($bottom-mobile > 0 and $bottom == 0) {
      @media (max-width: $max-tablet) {
        padding-bottom: $bottom-mobile * 1px;
      }
    }

    @if ($top > 0) {
      $padding: section-spacing($top-mobile, $top);

      @if ($top-mobile == 0) {
        @media (min-width: $min-tablet) {
          padding-top: $padding;
        }
      } @else {
        padding-top: $padding;
      }
    }
    @if ($bottom > 0) {
      $padding: section-spacing($bottom-mobile, $bottom);

      @if ($bottom-mobile == 0) {
        @media (min-width: $min-tablet) {
          padding-bottom: $padding;
        }
      } @else {
        padding-bottom: $padding;
      }
    }
  }

  &:empty {
    display: none;
  }
}

@mixin container($fluid: false) {
  flex-grow: 1;

  margin: 0 auto;
  padding-left: $container-gutter-mobile;
  padding-right: $container-gutter-mobile;

  width: 100%;

  @if $fluid == false {
    max-width: $page-width + $container-gutter-mobile * 2;
  }

  @supports (--css: variables) {
    padding-left: var(--container-gutter);
    padding-right: var(--container-gutter);

    @if $fluid == false {
      max-width: calc(#{$page-width} + var(--container-gutter) * 2);
    }
  }
}

@mixin object-position($x: 50, $y: 50, $fit: cover) {
  width: 100%;
  height: 100%;

  object-fit: $fit;

  object-position: calc(var(--object-x, #{$x}) * 1%) calc(var(--object-y, #{$y}) * 1%);
}
