@import 'styles/config';

.mediaAnimation {
  @include transition-enter-on-active($parent: true, $transform: false);
  contain: layout;

  position: relative;
  z-index: 0;

  width: 100%;
  max-height: 100%;

  overflow: visible;

  opacity: 0;

  transition-delay: var(--media-animation-delay);

  &::after {
    content: '';

    position: absolute;
    z-index: -1;
    inset: -1px;

    overflow: hidden;

    border-radius: 12px;

    background-color: var(--media-animation-background, var(--color-primary));

    transform: translate3d(0, 0, 0) scale3d(0.65, 0.65, 1);
  }

  &.active.topleft::after {
    transform: translate3d(-15px, -15px, 0) scale3d(0.65, 0.65, 1);

    @media (min-width: $min-desktop) {
      transform: translate3d(-60px, -60px, 0) scale3d(0.65, 0.65, 1);
    }

    transform-origin: 0 0;
  }

  &.active.topright::after {
    transform: translate3d(15px, -15px, 0) scale3d(0.65, 0.65, 1);

    @media (min-width: $min-desktop) {
      transform: translate3d(60px, -60px, 0) scale3d(0.65, 0.65, 1);
    }

    transform-origin: 100% 0;
  }

  &.active.bottomleft::after {
    transform: translate3d(-15px, 15px, 0) scale3d(0.65, 0.65, 1);

    @media (min-width: $min-desktop) {
      transform: translate3d(-60px, 60px, 0) scale3d(0.65, 0.65, 1);
    }

    transform-origin: 0 100%;
  }

  &.active.bottomright::after {
    transform: translate3d(15px, 15px, 0) scale3d(0.65, 0.65, 1);

    @media (min-width: $min-desktop) {
      transform: translate3d(60px, 60px, 0) scale3d(0.65, 0.65, 1);
    }

    transform-origin: 100% 100%;
  }

  &.active::after {
    opacity: 1;

    transition: all var(--media-animation-duration, #{$animation-duration}) ease-out var(--media-animation-delay, 0ms);
    transition-property: transform, opacity;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  &__ratio {
    display: block;

    padding-bottom: calc(100% * var(--ratio));
  }

  &__inner {
    @include fit;
    overflow: hidden;

    border-radius: 8px;

    .active & {
      transition: all var(--media-animation-duration, #{$animation-duration}) $default-ease var(--media-animation-delay, 0ms);
    }
  }

  &__media {
    @include media-position;
    @include fit;
    z-index: 2;
  }
}