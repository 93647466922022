// scss utils; style-agnostic values
@function strip-unit($number) {
  @if type-of($number)=='number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

@function make-percentage($a, $b) {
  @return math.div(strip-unit($a), strip-unit($b)) * 100%;

  @return $number;
}

// scale block according to specified font size.
// $size is pixel value of desired width, optional font-size
// would rather we "show working" rather than use mixins, but
// using mixin at least prevents having to @use math module
@function scale-to-em($size, $scale-font-size: $font-size) {
  @return math.div(strip-unit($size), strip-unit($scale-font-size)) * 1em;
}

// save having to @use scss math module per css module
@mixin aspect-ratio($width, $height) {
  display: block;

  padding-bottom: math.div($height, $width) * 100%;
}

// GENERIC css fit to relative / stack-ordered parent
@mixin fit($position: absolute) {
  inset: 0;

  position: $position;

  @supports not (inset: 0) {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// util for hiding
@mixin visually-hidden {
  position: absolute;

  height: 1px;
  width: 1px;
  overflow: hidden;

  clip: rect(0 0 0 0);
  // possible performance degradation?
  // clip-path: inset(50%);

  white-space: nowrap;
}

// object position custom properties for replaced elements (Video, Picture)
@mixin media-position($x: 50, $y: 50, $fit: cover, $background: false) {
  $position: calc(var(--object-x, #{$x}) * 1%) calc(var(--object-y, #{$y}) * 1%);

  @if ($background ==true) {
    background-size: $fit;
    background-repeat: no-repeat;
    background-position: $position;
  }

  @else {
    width: 100%;
    height: 100%;

    object-fit: $fit;

    object-position: $position;
  }
}

@mixin desktop-landscape-breakpoint {
  @media (min-aspect-ratio: 8 / 7) and (min-width: $min-desktop) {
    @content;
  }
}

@mixin tablet-landscape-breakpoint {
  @media (min-aspect-ratio: 8 / 7) and (min-width: $min-tablet) {
    @content;
  }
}

@mixin underline-hover {
  @media (pointer: fine) {
    &:hover {
      text-decoration: underline;
      text-decoration-color: rgba($color-background, 0.2);
      text-underline-offset: 4px;
    }
  }
}

@mixin footer-link {
  @include underline-hover;
  text-decoration: none;

  color: $color-font-contrast;
}

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}