@import 'styles/config';

.articleSection {
  @include section(
    $section-padding-article,
    $section-padding-article,
    $section-padding-article-mobile,
    $section-padding-article-mobile
  );

  & + &,
  & + &:empty + & {
    padding-top: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:last-of-type {
    padding-bottom: section-spacing();
  }
}
